import React, { useContext } from "react";
import { MainContext } from "../../../Context/MainContext";

import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import UilAngleDown from "@iconscout/react-unicons/icons/uil-angle-down";
import { filterTypes } from "../../../utils/types";

const CheckboxDropdown = ({ label, filter, values }) => {
  const { MainState, handleCheckboxSelect } = useContext(MainContext);

  const FilterCount = ({ value }) => {
    const allSpecials = MainState.staticSpecials;
    const activeFilters = MainState.filterSelections;

    const tempSpecials = Object.keys(activeFilters).reduce(
      (acc, key) => {
        if (key !== filter) {
          const matches = acc.filter((special) => {
            const currFilter = activeFilters[key];
            let isMatch = null;

            if (currFilter.length) {
              if (key === filterTypes.PRICE) {
                const priceHasValues = activeFilters.price.some((val) => {
                  return val !== "";
                });
                if (priceHasValues) {
                  const price = parseInt(special.sale_price);
                  const type = special.sale_type;
                  isMatch =
                    type === "buy" &&
                    price >= (parseInt(activeFilters.price[0]) || 0) &&
                    price <= (parseInt(activeFilters.price[1]) || Infinity);
                }
              } else if (key === filterTypes.PAYMENT) {
                const paymentHasValues = activeFilters.payment.some((val) => {
                  return val !== "";
                });
                if (paymentHasValues) {
                  const payment = parseInt(special.sale_payment);
                  const type = special.sale_type;
                  isMatch =
                    (type === "lease" || type === "finance") &&
                    payment >= (parseInt(activeFilters.payment[0]) || 0) &&
                    payment <= (parseInt(activeFilters.payment[1]) || Infinity);
                }
              } else if (key === filterTypes.SALE_TYPE) {
                const saleTypes = activeFilters.sale_type.map((type) => {
                  return type.toLowerCase();
                });
                isMatch = saleTypes.includes(special.sale_type.toLowerCase());
              } else if (key === filterTypes.CONDITION) {
                const condition = special.vehicle.new === true ? "new" : "used";
                isMatch = activeFilters.condition.includes(condition);
              } else {
                isMatch = currFilter.includes(special.vehicle[key]);
              }
            }
            return isMatch;
          });
          if (matches.length) {
            acc = matches;
          }
        }
        return acc;
      },
      [...allSpecials]
    );

    if (filter === filterTypes.SALE_TYPE) {
      return tempSpecials.filter((s) => {
        return value.toLowerCase() === s.sale_type.toLowerCase();
      }).length;
    } else if (filter === filterTypes.CONDITION) {
      return tempSpecials.filter((s) => {
        return value === (s.vehicle.new === true ? "new" : "used");
      }).length;
    } else {
      return tempSpecials.filter((s) => {
        return value === s.vehicle[filter];
      }).length;
    }
  };

  const isChecked = (val) => {
    return MainState.filterSelections[filter].indexOf(val) > -1 ? true : false;
  };

  return (
    <Dropdown>
      <Dropdown.Toggle
        // This bsPrefix removes the .dropdown-toggle class that shows the default caret
        bsPrefix=":"
        className="text-decoration-none d-flex align-items-center"
        variant="link-blue-600"
        id={`${filter}-dropdown`}
      >
        <span>{label}</span>
        <span>
          <UilAngleDown size="25" color="currentColor" />
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu className="border-0 shadow-lg max-h-52 overflow-auto">
        {values.map((val) => (
          <div className="px-4 py-1 mb-1" key={`${filter}-${val.value || val}`}>
            <Form.Check
              custom
              type="checkbox"
              id={`${filter}-${val.value || val}`}
            >
              <Form.Check.Input
                name={filter}
                checked={isChecked(val.value || val)}
                type="checkbox"
                onChange={(e) =>
                  handleCheckboxSelect(
                    filter,
                    val.value || val,
                    e.target.checked
                  )
                }
              />
              <Form.Check.Label>
                <div className="d-flex align-items-baseline">
                  <span className="font-weight-semibold mr-1 text-nowrap">
                    {val.text || val}
                  </span>
                  <span className="text-blue-600 text-2xs font-weight-semibold">
                    (<FilterCount value={val.value || val} />)
                  </span>
                </div>
              </Form.Check.Label>
            </Form.Check>
          </div>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CheckboxDropdown;
