import React, { useContext, useEffect, useState, useRef } from "react";
import { Container, Row, Col, Form, Spinner } from "react-bootstrap";
import UilSearch from "@iconscout/react-unicons/icons/uil-search";

import { MainContext } from "../../Context/MainContext";
import CheckboxDropdown from "./Dropdowns/CheckboxDropdown";
import RangeDropdown from "./Dropdowns/RangeDropdown";

const DesktopFilterContainer = ({ className }) => {
  const { MainState, handleSearchFilter } = useContext(MainContext);
  const [searchValue, setSearchValue] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      setIsSearching(true);
      const debounce = setTimeout(() => {
        handleSearchFilter(searchValue);
        setIsSearching(false);
      }, 500);

      return () => {
        clearTimeout(debounce);
      };
    }
    didMount.current = true;
  }, [searchValue]);

  useEffect(() => {
    setSearchValue(MainState.currentSearch);
  }, [MainState.currentSearch]);

  return (
    <div className={`bg-white shadow-sm ${className}`}>
      <Container fluid="xl">
        <Row>
          <Col>
            <div className="d-flex">
              <div className="d-flex flex-shrink-0">
                <div className="px-2 py-4 border-left border-right">
                  <RangeDropdown label="Price" />
                </div>
                <div className="px-2 py-4 border-right">
                  <CheckboxDropdown
                    label="Deal Type"
                    filter="sale_type"
                    values={[
                      { text: "APR", value: "apr" },
                      { text: "Buy", value: "buy" },
                      { text: "Finance", value: "finance" },
                      { text: "Lease", value: "lease" },
                      { text: "Rebate", value: "rebate" },
                    ]}
                  />
                </div>
                <div className="px-2 py-4 border-right">
                  <CheckboxDropdown
                    label="Body Style"
                    filter="body"
                    values={MainState.allBodyStyles}
                  />
                </div>
                <div className="px-2 py-4 border-right">
                  <CheckboxDropdown
                    label="Year"
                    filter="year"
                    values={MainState.allYears}
                  />
                </div>
                {MainState.noMakes === false && <div className="px-2 py-4 border-right">
                  <CheckboxDropdown
                    label="Make"
                    filter="make"
                    values={MainState.allMakes}
                  />
                </div>}
                {!(MainState.noMakes === true && MainState.filterSelections.make.length > 0 && (MainState.filterSelections.make[0] == 'Mercedes-Benz' || MainState.filterSelections.make[0] == 'Honda')) && <div className="px-2 py-4 border-right">
                  <CheckboxDropdown
                    label="Condition"
                    filter="condition"
                    values={[
                      { text: "New", value: "new" },
                      { text: "Used", value: "used" },
                    ]}
                  />
                </div>}
                {/* <div className="px-2 py-4 border-right">
                  <CheckboxDropdown
                    label="Location"
                    filter="dealer_id"
                    values={MainState.allLocations.map((val) => ({
                      text: val.name,
                      value: val.id,
                    }))}
                  />
                </div> */}
              </div>
              <div className="py-4 pl-5 flex-grow-1">
                <div className="position-relative">
                  <Form.Control
                    className="pl-10"
                    type="search"
                    placeholder="Search by year, make, model…"
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                    value={searchValue}
                  />
                  <div className="position-absolute w-10 h-100 text-slate-500 d-flex align-items-center justify-content-center top-0 pl-2">
                    <UilSearch
                      size="20"
                      color="currentColor"
                      className="mr-2"
                    />
                  </div>
                  {isSearching && (
                    <div className="position-absolute top-0 right-0 pr-4 h-100 d-flex align-items-center">
                      <Spinner
                        variant="blue-500"
                        animation="border"
                        size="sm"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DesktopFilterContainer;
