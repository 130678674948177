import React, { useContext } from "react";

import { MainContext } from "../../Context/MainContext";
import { Button, Modal } from "react-bootstrap";
import CheckboxCollapse from "../Filter/Collapse/CheckboxCollapse";
import RangeCollapse from "../Filter/Collapse/RangeCollapse";

const MobileFiltersModal = () => {
  const { MainState, triggerModal, clearFilters } = useContext(MainContext);

  const checkboxFilters = [
    {
      label: "Deal Type",
      filter: "sale_type",
      values: [
        { text: "APR", value: "apr" },
        { text: "Buy", value: "buy" },
        { text: "Finance", value: "finance" },
        { text: "Lease", value: "lease" },
        { text: "Rebate", value: "rebate" },
      ],
    },
    { label: "Body Style", filter: "body", values: MainState.allBodyStyles },
    { label: "Year", filter: "year", values: MainState.allYears },
    { label: "Make", filter: "make", values: MainState.allMakes },
    {
      label: "Condition",
      filter: "condition",
      values: [
        { text: "New", value: "new" },
        { text: "Used", value: "used" },
      ],
    },
    // {
    //   label: "Location",
    //   filter: "dealer_id",
    //   values: MainState.allLocations.map((val) => ({
    //     text: val.name,
    //     value: val.id,
    //   })),
    // },
  ].filter((val) => {
    if (val.label === "Make" && MainState.noMakes) {
      return false;
    }
    if (val.label === "Condition") {
        return MainState.noMakes === false && MainState.filterSelections.make.length > 0 && (MainState.filterSelections.make[0] == 'Mercedes-Benz' || MainState.filterSelections.make[0] == 'Honda')
    }
    return true;      
  });

  const activeFilterObjects = Object.keys(MainState.filterSelections).reduce(
    (acc, key) => {
      let filterObj = MainState.filterSelections[key].length
        ? MainState.filterSelections[key].map((val) => {
            return {
              filter: key,
              value: val,
            };
          })
        : [];
      return acc.concat(filterObj);
    },
    []
  );

  return (
    <>
      <Modal.Body className="p-0">
        <div className="position-relative p-4 d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <span className="text-lg font-weight-bold">Filters</span>
            {activeFilterObjects.length > 0 && (
              <span className="bg-blue-600 rounded-pill text-white text-xs ml-2 min-w-4 text-center">
                {activeFilterObjects.length}
              </span>
            )}
          </div>
          <div className="d-flex">
            {activeFilterObjects.length > 0 && (
              <div className="mr-4">
                <Button
                  variant="link-blue-500"
                  size="sm"
                  className="text-decoration-none shadow-none"
                  onClick={() => clearFilters()}
                >
                  <span className="font-weight-semibold">Clear Filters</span>
                </Button>
              </div>
            )}
            <div>
              <Button
                onClick={() => triggerModal()}
                size="sm"
                variant="blue-500"
                className="d-flex align-items-center justify-content-center transition-none"
              >
                Apply
              </Button>
            </div>
          </div>
        </div>
        <div>
          <RangeCollapse label="Price" />
          {checkboxFilters.map((filter, idx) => (
            <CheckboxCollapse
              key={idx}
              label={filter.label}
              filter={filter.filter}
              values={filter.values}
            />
          ))}
        </div>
      </Modal.Body>
    </>
  );
};

export default MobileFiltersModal;
