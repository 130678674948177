import React, { useContext, useEffect } from "react";

import { Form } from "react-bootstrap";
import { MainContext } from "../../Context/MainContext";

const MobileSortContainer = () => {
  const { MainState, handleSortSelect, triggerModal } = useContext(MainContext);

  const sortOptions = MainState.sortOptions;
  const sortValue = MainState.sortValue;

  const handleSort = (value) => {
    handleSortSelect(value);
    triggerModal();
  };

  // Initialize sort value
  useEffect(() => {
    handleSortSelect(sortValue);
  }, []);

  return (
    <div>
      {sortOptions.map((option) => (
        <div className="py-2 mb-1" key={option.value}>
          <Form.Check className="w-100" custom type="radio" id={option.value}>
            <Form.Check.Input
              name="sort"
              type="radio"
              checked={sortValue === option.value}
              onChange={() => handleSort(option.value)}
              className="transition-none"
            />
            <Form.Check.Label className="w-100">
              <div className="d-flex align-items-baseline">
                <span className="font-weight-semibold mr-1 text-nowrap">
                  {option.text}
                </span>
              </div>
            </Form.Check.Label>
          </Form.Check>
        </div>
      ))}
    </div>
  );
};

export default MobileSortContainer;
