import React, { useContext } from "react";
import { Row, Col, Button } from "react-bootstrap";

import { MainContext } from "../../Context/MainContext";
import Price from "../Results/Special/Price";
import SpecialBadge from "../Results/Special/SpecialBadge";
import DealTerms from "../Landing/DealTerms";
import UilShareAlt from "@iconscout/react-unicons/icons/uil-share-alt";
import UilInfoCircle from "@iconscout/react-unicons/icons/uil-info-circle";
import UilLink from "@iconscout/react-unicons/icons/uil-link";

const Details = ({ special }) => {
  const { triggerModal, setActiveSpecial, gaEvent } = useContext(MainContext);
  console.log(special);

  const handleClick = (modal) => {
    setActiveSpecial(special);
    gaEvent(modal, "click", "openModal");
    triggerModal(modal);
  };

  const setTaxesAndFees = () => {
    if (
      special.plus_acquisition_fee != "1" ||
      special.plus_registration_fee != "1"
    ) {
      if (special.plus_sales_tax != "1") {
        return "+ Taxes & Fees";
      } else {
        return "+ Fees";
      }
    } else if (special.plus_sales_tax != "1") {
      return "+ Taxes";
    }
  };

  return (
    <Row>
      <Col md={6}>
        <div className="mb-2">
          <div>
            <span
              className={`d-flex text-xl font-weight-semibold leading-tight ${
                special.vehicle.make.toLowerCase() === "nissan"
                  ? "font-nissan-brand"
                  : special.vehicle.make.toLowerCase() === "infiniti"
                  ? "font-infiniti-brand"
                  : ""
              }`}
            >
              {special.vehicle.year} {special.vehicle.make}{" "}
              {special.vehicle.model} {special.vehicle.series}
            </span>
          </div>
          <div>
            <span className="d-flex text-gray-700 text-sm">
              {special.vehicle.body}
              {special.vehicle.drivetrain && ` • ${special.vehicle.drivetrain}`}
            </span>
          </div>
        </div>
        <div className="mb-6 mb-md-0">
          <SpecialBadge
            condition={special.vehicle.new}
            type={special.sale_type}
          />
        </div>
        {special.available_search_url && (
          <div className="mb-6 mb-md-0 mt-6">
            <a href={special.available_search_url} target="_blank">
              <UilLink size="18" color="currentColor" />
              View all applicable vehicles
            </a>
          </div>
        )}
      </Col>
      <Col
        md={6}
        className="border-bottom border-top border-md-top-0 border-md-bottom-0"
      >
        <div className="d-flex align-items-center py-4 py-md-0">
          <div className="mr-6">
            <Button
              variant="link"
              className="text-decoration-none text-blue-500 d-flex align-items-center p-1 shadow-none"
              onClick={() => handleClick("share")}
            >
              <div className="d-flex mr-1">
                <UilShareAlt size="18" color="currentColor" />
              </div>
              <span className="text-uppercase font-weight-bold text-sm tracking-wide">
                Share
              </span>
            </Button>
          </div>
          <Button
            variant="blue-500"
            block
            onClick={() => handleClick("contact")}
          >
            <span className="font-weight-bold">Contact Us</span>
          </Button>
        </div>
      </Col>
      {special.down_payment && special.down_payment > 0 && (
        <Col className="d-md-none border-bottom" md={6}>
          <div className="py-2 text-gray-700 d-flex align-items-center">
            <span className="d-flex mr-2">
              <UilInfoCircle size="22" color="currentColor" />
            </span>
            <span className="text-sm font-weight-semibold leading-none">
              ${Number(special.down_payment).toLocaleString()} Down Payment{" "}
              {setTaxesAndFees()}
            </span>
          </div>
        </Col>
      )}
      {special.special_type == "apr" && (
        <Col className="d-md-none border-bottom" md={6}>
          <div className="py-2 text-gray-700 d-flex align-items-center">
            <span className="d-flex mr-2">
              <UilInfoCircle size="22" color="currentColor" />
            </span>
            <span className="text-sm font-weight-semibold leading-none">
              On approved credit, down payment may be required
            </span>
          </div>
        </Col>
      )}
      <Col md={6}>
        <div className="mt-6 mb-8">
          {special.down_payment && special.down_payment > 0 && (
            <div className="d-none d-md-block">
              <div className="border rounded p-2 text-gray-700 d-flex align-items-center mb-4">
                <span className="d-flex mr-2">
                  <UilInfoCircle size="22" color="currentColor" />
                </span>
                <span className="text-sm font-weight-semibold leading-none">
                  ${Number(special.down_payment).toLocaleString()} Down Payment{" "}
                  {setTaxesAndFees()}
                </span>
              </div>
            </div>
          )}
          {special.special_type == "apr" && (
            <Col className="d-md-none border-bottom" md={6}>
              <div className="py-2 text-gray-700 d-flex align-items-center">
                <span className="d-flex mr-2">
                  <UilInfoCircle size="22" color="currentColor" />
                </span>
                <span className="text-sm font-weight-semibold leading-none">
                  On approved credit, down payment may be required
                </span>
              </div>
            </Col>
          )}
          <ul className="pl-4">
            {special.bullets
              .filter((i) => i && i.length > 0)
              .slice(0, 3)
              .map((i, k) => (
                <li key={k}>{i}</li>
              ))}
            {special.vehicle.new === false && (
              <li>
                Mileage: {Number(special.vehicle.odometer).toLocaleString()}
              </li>
            )}
          </ul>
        </div>
        {/* <div className="mb-8 mb-md-0">
          <div className="mb-2">
            <span className="text-uppercase text-blue-600 font-weight-bold text-sm tracking-wider">
              Vehicle Details
            </span>
          </div>
          <p className="mb-0">
            Oxford White 2019 Ford E-450SD DRW RWD 6-Speed Triton V10 6.8L EFI
            16" x 6" White Painted Steel Wheels, ABS brakes, GVWR: 14,500 lb
            Payload Package.
          </p>
        </div> */}
      </Col>
      <Col md={6}>
        <div className="border rounded bg-slate-50">
          <Price
            leftColClass="py-2"
            rightColClass="py-2 border-left"
            className="border-bottom px-4"
            special={special}
          />
          <div className="p-4">
            <div className="mb-2">
              <span className="text-uppercase text-blue-600 font-weight-bold text-sm tracking-wider">
                Deal Terms
              </span>
            </div>
            <div className="text-sm">
              <DealTerms special={special} />
              {special.vehicle.new === false && (
                <p className="mb-0 mt-3">
                  We cannot guarantee a recall-free vehicle. For recall
                  information please click{" "}
                  <a
                    href="https://www.safercar.gov/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </a>
                  .
                </p>
              )}
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Details;
